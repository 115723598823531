import request, { RequestMethod } from "@/lib/requestClient";
import { useStore } from "@/lib/store";
import { Sparkle } from "lucide-react";

interface UpgradeButtonProps extends React.HTMLAttributes<HTMLDivElement> {}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ ...rest }) => {
  const [currentOrganization, _] = useStore(
    "userStore",
    ({ organization }) => organization
  );

  const upgradeCheckout = async () => {
    const endpoint = request.endpoint(
      `organizations/${currentOrganization.id}/billing/manage`
    );
    const requestParams = request.createDefaultParams(RequestMethod.GET);
    const res = await request.call(endpoint, requestParams);
    if (res.ok) {
      window.open(await res.text(), "_blank");
    }
  };

  if (currentOrganization && currentOrganization.subscription.type === "FREE") {
    return (
      <div {...rest}>
        <button onClick={upgradeCheckout} className="btn btn-outline btn-xs">
          Upgrade To Team
          <Sparkle className="h-4 w-4 ml-[-0.3rem]" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default UpgradeButton;
