import { FC, useState } from "react";

interface Props extends React.PropsWithChildren {
  title?: string;
  buttonText?: string;
  buttonStyles?: string;
  modalThemeStyles?: React.CSSProperties;
  baseButtonStyle?: string;
  buttonIcon?: Element;
  modalStyles?: string;
  modalContainerStyles?: string;
  eagerRender?: boolean;
  id: string;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const GenericModal: FC<Props> = ({
  title = "",
  buttonText = "Open",
  modalStyles = "",
  modalThemeStyles = {},
  modalContainerStyles = "",
  buttonStyles = "",
  baseButtonStyle = "btn",
  eagerRender = true,
  buttonIcon,
  id,
  children,
}: Props) => {
  var buttonContents = buttonIcon || buttonText || "";
  const [showModal, setShowModal] = useState(eagerRender);

  return (
    <>
      <button
        title={title}
        className={classNames(baseButtonStyle, buttonStyles)}
        onClick={() => {
          setShowModal(true);
          document.getElementById(id).showModal();
        }}
      >
        {buttonContents}
      </button>
      <dialog
        id={id}
        className={classNames("modal", modalStyles)}
        style={modalThemeStyles}
      >
        <div
          className={classNames(
            "absolute top-auto rounded-lg overflow-auto max-h-screen",
            modalContainerStyles
          )}
        >
          {showModal && children}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default GenericModal;
