import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Theme } from "@/stores/themes";
import {
  uniqueNamesGenerator,
  Config,
  colors,
  animals,
} from "unique-names-generator";
import { GithubIcon } from "lucide-react";
import { PulseLoader } from "react-spinners";

const CreateProjectForm = ({
  onSubmit,
  theme,
  title,
  handleUseGithub,
}: {
  onSubmit: any;
  theme: Theme;
  title: string;
  handleUseGithub: any;
}) => {
  const [spinner, setSpinner] = useState(false);
  const customConfig: Config = {
    dictionaries: [colors, animals],
    separator: "-",
    length: 2,
  };

  const shortName: string = uniqueNamesGenerator(customConfig); // pink-albatross

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: shortName,
    },
  });

  return (
    <form
      className="space-y-6 border-[1px] p-8 h-1/2 w-[400px] flex flex-col justify-around rounded-lg"
      style={{
        backgroundColor: theme.ui.panels,
        color: theme.ui.titleBarText,
        borderColor: theme.ui.borders,
      }}
      onSubmit={handleSubmit((data) => {
        setSpinner(true);
        onSubmit(data);
      })}
    >
      <h3>{title}</h3>
      <div>
        <label htmlFor="name" className="block text-lg font-medium leading-6">
          Project Name
        </label>
        <div className="mt-2">
          <input
            id="name"
            type="text"
            {...register("name", {
              required: "project name is required",
            })}
            required
            className="block w-full rounded-md border-0 py-1.5 text-neutral shadow-none bg-gray-100 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          />
          {errors.name && (
            <span className="text-xs text-red-500">
              {errors.name.message?.toString()}
            </span>
          )}
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="btn btn-primary w-full my-2 capitalize"
          disabled={spinner}
        >
          {spinner ? <PulseLoader size={10} color="white" /> : "Create!"}
        </button>
      </div>
      <p className="text-sm font-light text-center">or</p>
      <div>
        <button
          className="btn btn-primary w-full my-2 capitalize"
          onClick={handleUseGithub}
        >
          Link from GitHub <GithubIcon strokeWidth="1px" size={24} />
        </button>
      </div>
    </form>
  );
};

export default CreateProjectForm;
