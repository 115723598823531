import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Theme } from "@/stores/themes";
import request, { RequestMethod } from "@/lib/requestClient";
import { useStore } from "@/lib/store";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { PulseLoader } from "react-spinners";
import { useRouter } from "next/router";

const LinkGitHubRepoForm = ({
  onSubmit,
  theme,
  handleBack,
}: {
  onSubmit: any;
  theme: Theme;
  handleBack: any;
}) => {
  const router = useRouter();
  const [organization, _updateOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );
  const [spinner, setSpinner] = useState(false);

  const { register, handleSubmit } = useForm({});

  const [repos, setRepos] = useState<any[] | null>(null);

  useEffect(() => {
    if (!organization) return;
    (async () => {
      const repoListEndpoint = request.endpoint(
        `organizations/${organization.id}/projects/connect/github/repo`
      );
      const requestParams = request.createDefaultParams(RequestMethod.GET);
      const resp = await request.call(repoListEndpoint, requestParams);
      const data = await resp.json();
      setRepos(data.sort((a: any, b: any) => a.repo.localeCompare(b.repo)));
    })();
  }, [organization]);

  if (repos === null) {
    return (
      <div
        className="space-y-6 border-[1px] p-8 h-1/2 w-[400px] flex flex-col justify-around rounded-lg"
        style={{
          backgroundColor: theme.ui.panels,
          color: theme.ui.titleBarText,
          borderColor: theme.ui.borders,
        }}
      >
        <p>Loading Repositories...</p>
        <PulseLoader size={10} color="white" />
      </div>
    );
  } else if (repos.length === 0) {
    router.push(
      process.env.NEXT_PUBLIC_GITHUB_APP_LINK +
        "?state=" +
        encodeURIComponent("/projects?linkGithub=true")
    );
    return <></>;
  } else {
    return (
      <form
        className="space-y-6 border-[1px] p-8 h-1/2 w-[400px] flex flex-col justify-around rounded-lg"
        style={{
          backgroundColor: theme.ui.panels,
          color: theme.ui.titleBarText,
          borderColor: theme.ui.borders,
        }}
        onSubmit={handleSubmit((data) => {
          setSpinner(true);
          onSubmit(repos[data.repo]);
        })}
      >
        <button
          className="btn btn-square btn-sm"
          style={{ outlineColor: theme.ui.titleBarText }}
          onClick={handleBack}
        >
          <ArrowLeftIcon
            strokeWidth="1px"
            className="h-5"
            style={{ stroke: theme.ui.titleBarText }}
          />
        </button>
        <p className="text-lg font-medium">Select a Repository</p>
        <div>
          <select className="select w-full" {...register("repo")}>
            {repos.map((repo, idx) => (
              <option key={idx} value={idx}>
                {repo.repo}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-primary w-full my-2 capitalize"
            disabled={spinner}
          >
            {spinner ? <PulseLoader size={10} color="white" /> : "Create!"}
          </button>
        </div>
        <a
          className="text-xs"
          href={
            process.env.NEXT_PUBLIC_GITHUB_APP_LINK +
            "?state=" +
            encodeURIComponent("/projects?linkGithub=true")
          }
        >
          Need to install the GitHub App?
        </a>
      </form>
    );
  }
};

export default LinkGitHubRepoForm;
