import GenericModal from "../GenericModal";
import CreateProjectForm from "../CreateProjectForm";
import LinkGitHubRepoForm from "../LinkGitHubRepoForm";
import { Theme } from "@/stores/themes";
import { useStore } from "@/lib/store";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export const createProjectsModalId = "docsCreateModalId";

const CreateComponent = ({
  handleCreateProjectSubmit,
  theme,
  title,
}: {
  handleCreateProjectSubmit: CallableFunction;
  theme: Theme;
  title: string;
}) => {
  const router = useRouter();
  // Toggle between the two forms
  const [github, setGithub] = useState(false);
  const { linkGithub } = router.query;
  useEffect(() => {
    if (linkGithub) {
      setGithub(true);
    }
  }, [linkGithub]);

  return (
    <div className="w-2/5 ml-auto mr-auto flex flex-col mt-24 items-center">
      {github ? (
        <LinkGitHubRepoForm
          theme={theme}
          onSubmit={handleCreateProjectSubmit}
          handleBack={() => setGithub(false)}
        />
      ) : (
        <CreateProjectForm
          title={title}
          theme={theme}
          onSubmit={handleCreateProjectSubmit}
          handleUseGithub={() => setGithub(true)}
        />
      )}
    </div>
  );
};

interface CreateProjectModalProps {
  button: Element;
  buttonStyles: string;
  createHandler: Function;
  opaque: boolean;
  theme: Theme;
  firstProject: boolean;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  button,
  buttonStyles,
  createHandler,
  theme,
  firstProject = false,
  opaque = false,
}) => {
  const [themes, _updateThemes] = useStore<Theme[]>(
    "themesStore",
    ({ themes }) => themes
  );
  const [currentTheme, _setCurrentTheme] = useStore<number>(
    "themesStore",
    ({ currentTheme }) => currentTheme
  );

  const actualTheme = themes[currentTheme];

  const formTitle = firstProject
    ? "Get Started Creating Your First Project"
    : "Create Your Project";

  return (
    <GenericModal
      title="Create Project"
      id={createProjectsModalId}
      buttonIcon={button}
      modalContainerStyles="overflow-hidden -mt-[20%]"
      buttonStyles={buttonStyles}
    >
      <CreateComponent
        title={formTitle}
        theme={theme || actualTheme}
        handleCreateProjectSubmit={createHandler}
      />
    </GenericModal>
  );
};

export default CreateProjectModal;
