import ProjectSelectorComponent from "@/components/projects/ProjectSelectorComponent";
import { useStore } from "@/lib/store";
import styles from "@/styles/Projects.module.css";
import request from "@/lib/requestClient";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { getAndSetProjects } from "@/stores/projects";
import { refreshTokens } from "@/lib/authentication";

export default function Projects() {
  const router = useRouter();
  const [user, updateUser] = useStore("userStore", ({ user }) => user);
  const [project, _updateProject] = useStore(
    "projectStore",
    ({ project }) => project
  );
  const [organization, updateOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );

  useEffect(() => {
    if (organization !== undefined) {
      getAndSetProjects();
    }
  }, [organization]);

  const [projects, updateProjects] = useStore(
    "projectStore",
    ({ projects }) => projects
  );

  // TODO: should this be hoisted to _app.tsx?
  const refreshCallback = (user) => {
    updateUser({ user }, () => {
      if (localStorage.getItem("organizationId")) {
        const orgId = parseInt(
          localStorage.getItem("organizationId") as string
        );
        const org = user.organizations.find((org) => org.id === orgId);
        updateOrganization({ organization: org }, () => {
          getAndSetProjects();
        });
      } else {
        localStorage.setItem(
          "organizationId",
          user.organizations[0].id.toString()
        );
        updateOrganization({ organization: user.organizations[0] }, () => {
          getAndSetProjects();
        });
      }
    });
  };

  useEffect(() => {
    if (user === undefined) {
      const refreshToken = localStorage.getItem("refreshToken");
      refreshTokens(refreshToken, refreshCallback);
    }
  }, [user]);

  const handleCreateProjectSubmit = async (data: any) => {
    const endpoint = request.endpoint(
      `organizations/${organization.id}/projects`
    );
    const params = request.createDefaultParams();
    params.method = "POST";

    params.body = JSON.stringify(data);

    request.call(endpoint, params).then(async (res) => {
      if (res.ok) {
        const endpoint = request.endpoint(
          `organizations/${organization.id}/projects/list`
        );
        const project = await res.json();

        request
          .call(endpoint, request.createDefaultParams())
          .then(async (res) => {
            const resJson = await res.json();

            updateProjects(
              { projects: resJson.projects, project: project.id },
              () => {
                router.replace("/build");
              }
            );
          });
      }
    });
  };

  const base = (
    <ProjectSelectorComponent createHandler={handleCreateProjectSubmit} />
  );

  return (
    <>
      <Head>
        <title>Projects</title>
        <meta
          name="description"
          content="Create and manage your Bismuth Projects"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <main className={styles.main}>{base}</main>
    </>
  );
}
