/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin')


module.exports = {
  content: [
    // Or if using `src` directory:
    "./src/**/*.{js,ts,jsx,tsx,md}",
    "./src/components/docs/*.{tsx}",
    "./src/pages/docs/*.{md}",
    "./src/pages/*.{md}"
  ],
  theme: {
    extend: {
      colors: {
        panel: {
          sideBar: "#1b1e2b",
          activityBar: "#1b1e2b",
          titleBar: "#1b1e2b",
          tabs: "#292c3e",
          tabsActive: "#232636",
          tabsActiveBorder: "#5affb2",
          borders: "#000000",
          buttons: "#2a4c82",
          buttonsHover: "#1d9bee",
          text: "#b1e2ff"
        },
        editor: {

        }
      },
      keyframes: {
        'pulse-grow-shrink': {
          '0%, 100%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.5)' },
        },
        'color-pulse': {
          '0%, 100%': { backgroundColor: 'var(--primary-color)' },
          '50%': { backgroundColor: 'var(--dark-color)' },
        },
      },
      animation: {
        'pulse-grow-shrink': 'pulse-grow-shrink 1.2s infinite ease-in-out',
        'color-pulse': 'color-pulse 1.2s infinite ease-in-out',
      },
      screens: {
        'xs': '220px',
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("@tailwindcss/forms"),
    require("daisyui"),
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.no-scrollbar::-webkit-scrollbar': {
          'display': 'none',
        },
        '.no-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        },
      })
    })
  ],
  daisyui: {
    styled: true,
    themes: [
      "light",
      "dark",
      {
        darker: {
          ...require("daisyui/src/theming/themes")["dark"],
          "neutral-content": "#f5f5f4",
          "py": "#FFE873",
          primary: "#661AE6",
          "primary-content": "#ffffff",
          secondary: "#D926AA",
          "secondary-content": "#ffffff",
          accent: "#1FB2A5",
          "accent-content": "#ffffff",
          neutral: "#191D24",
          "neutral-focus": "#111318",
          "base-100": "#2A303C",
          "base-200": "#242933",
          "base-300": "#20252E",
          "base-content": "#A6ADBB",
        }
      },
      {
        torb: {
          "primary": "#fde047",

          "secondary": "#f97316",

          "accent": "#0284c7",

          "neutral": "#211B27",

          "base-100": "#243447",

          "info": "#98B2E6",

          "success": "#1C7D49",

          "warning": "#fca5a5",

          "error": "#e11d48",
        }
      },
      {
        darkBismuth: {

          "primary": "#748df2",

          "secondary": "#54d35b",

          "accent": "#4786aa",

          "neutral": "#23222A",

          "base-100": "#243051",

          "info": "#98ABF0",

          "success": "#145D3E",

          "warning": "#F5A856",

          "error": "#EB2D37",
        },
      },
      "cyberpunk"
    ],
    base: true,
    utils: true,
    logs: true,
    rtl: false,
    prefix: "",
    darkTheme: "dark",
  },
}
